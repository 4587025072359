import get_correct_translation_value from "./get_correct_translation_value"

export default (chosen_options, variants, selected_language) => {
  if (chosen_options.length) {
    const corrent_variant = chosen_options.length ?
      variants.find(
        (variant) => chosen_options.every(
          ({ value }) => get_correct_translation_value(variant, "title", [selected_language]).includes(value)
        )
      ) :
      variants[0]

    return {
      price: get_correct_translation_value(corrent_variant, "price", [selected_language], false),
      compare_at_price: get_correct_translation_value(corrent_variant, "compare_at_price", [selected_language], false)
    }
  }

  return {
    price: get_correct_translation_value(variants[0], "price", [selected_language], false),
    compare_at_price: get_correct_translation_value(variants[0], "compare_at_price", [selected_language], false)
  }
}
